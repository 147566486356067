<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card :elevation="isEmbedded ? 0 : 2">
      <v-card-title v-if="!isEmbeddedInApp" class="display-2">Welcome!</v-card-title>

      <v-stepper v-model="currentStep" alt-labels flat elevation="0" tile>
        <v-stepper-header>
          <v-stepper-step :complete="currentStep > 1" step="1"  >
            Start
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 2" step="2"  >
            Profile
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 3" step="3"  >
            Preferences
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 4" step="4">
            Device
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 5" step="5">
            Team
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 6" step="6">
            Summary
          </v-stepper-step>

        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <h3>
              <span v-if="profile && profile.given_name">Hi {{profile.given_name}} 👋 </span>
              <span>Welcome to the {{ tenant.title }}</span>
            </h3>
            <div v-if="!profile" class="align-center">
              <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mb-8"></v-progress-circular>
              <div>{{ $t('shared.loading')}}</div>
            </div>
            <div v-if="profile">
              <p>
                Please complete this onboarding to ensure we capture your profile preferences correctly for an optimal experience of {{ tenant.name }}.
              </p>
              <p>
                This one-time wizard will help you get started and ensures your profile is set according to your preferences.
              </p>
              <v-alert type="info">
                Please note that you will have to <strong>re-connect your fitness tracker/app</strong> after completion of this onboarding wizard. 
              </v-alert>
            </div>

            <div class="d-flex">
              <v-btn v-if="currentStep > 1" text @click="currentStep--">
                Back
              </v-btn>

              <v-spacer />

              <v-btn color="primary" @click="currentStep++" :loading="$store.getters.isLoading">
                Next
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="profile" step="2">
            <h3>Your Profile</h3>
            <p>We have automatically migrated your profile details to the new platform. Please review this information to ensure it's still accurate.</p>

            <v-text-field 
                v-model="profile.name" 
                :rules="nameRules" 
                :label="$t('profile.edit.displayname')" 
                prepend-icon="fa-user"
                required
                />

              <v-text-field 
                v-model="profile.email" 
                :rules="emailRules" 
                :label="$t('profile.edit.email')" 
                prepend-icon="fa-envelope"
                dense
                >
              </v-text-field>
              <i18n path="profile.edit.privacy-message" tag="p" class="ml-8 text-muted">
                <router-link to="/privacypolicy" target="_blank">{{$t('account.register.terms_privacy_privacy')}}</router-link>
              </i18n>

            <v-menu
              v-model="showDobPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formattedDob"
                  :label="$t('profile.edit.birthdate')"
                  persistent-hint
                  prepend-icon="fa-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker 
                no-title 
                ref="dobPicker"
                v-bind:value="dob"
                v-on:input="handleDobChange"
                :max="new Date().toISOString().substr(0, 10)"
                min="1920-01-01"
                >
              </v-date-picker>
            </v-menu>

            <p class="mb-0">{{$t('profile.edit.gender')}}</p>
            <v-radio-group v-model="gender" row class="mt-0" >
              <v-radio :label="$t('shared.gender-undisclosed')" value="_"></v-radio>
              <v-radio :label="$t('shared.gender-male')" value="M"></v-radio>
              <v-radio :label="$t('shared.gender-female')" value="F"></v-radio>
              <v-radio :label="$t('shared.gender-nb')" value="O"></v-radio>
            </v-radio-group>
            
            <!-- <v-text-field 
                v-model="profile.nationality" 
                :label="$t('profile.edit.nationality')" 
                prepend-icon="fa-user"
                required
                /> -->

            <div class="d-flex">
              <v-btn v-if="currentStep > 1" text @click="currentStep--">
                Back
              </v-btn>

              <v-spacer />

              <v-btn outlined color="primary" class="me-4" @click="currentStep++">
                Skip
              </v-btn>
              <v-btn color="primary" @click="currentStep++" :loading="$store.getters.isLoading">
                Next
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="profile" step="3">
            <h3>Your Preferences</h3>
            <p>Please select your platform preferences. Unfortunately, we were not able to migrate this data for you.</p>

            <p class="mt-4 mb-0">Unit system</p>
            <v-radio-group v-model="unit" row class="mt-0">
              <v-radio :label="$t('profile.edit.unit-metric')" value="METRIC"></v-radio>
              <v-radio :label="$t('profile.edit.unit-imperial')" value="IMPERIAL"></v-radio>
            </v-radio-group>

            <p class="mt-4 mb-0">Profile visibility</p>
            <v-radio-group v-model="privateProfile" row class="mt-0">
              <v-radio label="Private" :value="true"></v-radio>
              <v-radio label="Visible" :value="false"></v-radio>
            </v-radio-group>
            <p v-if="!notSet(privateProfile) && privateProfile" class="my-0" >
              {{ $t('profile.edit.private-label') }}
            </p>

            <div class="d-flex">
              <v-btn v-if="currentStep > 1" text @click="currentStep--">
                Back
              </v-btn>

              <v-spacer />

              <!-- <v-btn outlined color="primary" class="me-4" @click="currentStep++">
                Skip
              </v-btn> -->
              <v-btn :disabled="!preferencesValid" color="primary" @click="currentStep++" :loading="$store.getters.isLoading">
                Next
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="profile" step="4">
            <h3>Device</h3>

            <p>You will have to re-connect your device or app to the new platform to continue syncing your activities.</p>

            <p>Please select your currently connected device.</p>
            <v-select
              v-model="selectedDeviceValue"
              :items="devices"
              item-text="text"
              item-value="value"
              solo-inverted
              class="mx-1"
              />

            <v-alert v-if="selectedDevice && selectedDevice.support == 'YES'" type="success">
              <p>Good news. You can re-connect your {{selectedDevice.text}} to resume syncing again.</p>
              <p><v-btn v-if="selectedDevice.faq" :href="selectedDevice.faq" target="_blank">Read more</v-btn></p>
            </v-alert>

            <v-alert v-if="selectedDevice && selectedDevice.support == 'INDIRECT'" type="info">
              <p>Good news, you can keep using {{selectedDevice.text}}. Please keep in mind to connect through either Apple Health or Google Health Connect.</p>
              <p><v-btn v-if="selectedDevice.faq" :href="selectedDevice.faq" target="_blank">Read instructions</v-btn></p>
            </v-alert>

            <v-alert v-if="selectedDevice && selectedDevice.support == 'NO'" type="info">
              <p>We don't natively support your device on the new platform. However, we have plenty of integrations available to help you get connected.</p>
              <p><v-btn v-if="selectedDevice.faq" :href="selectedDevice.faq" target="_blank">Find Alternatives</v-btn></p>
            </v-alert>

            <div class="d-flex">
              <v-btn v-if="currentStep > 1" text @click="currentStep--">
                Back
              </v-btn>

              <v-spacer />

              <v-btn outlined color="primary" class="me-4" @click="currentStep++">
                Skip
              </v-btn>
              <v-btn :disabled="!selectedDevice" color="primary" @click="currentStep++" :loading="$store.getters.isLoading">
                Next
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="profile" step="5">
            <h3>Team</h3>

            <p>Please select your country team. You can also skip this for now and do this later.</p>
            
            <v-autocomplete 
              v-model="selectedGroupId"
              :items="groups"
              item-value="id"
              item-text="name"
              solo-inverted
              placeholder="Select your team"
              />

            <div class="d-flex">
              <v-btn v-if="currentStep > 1" text @click="currentStep--">
                Back
              </v-btn>

              <v-spacer />

              <v-btn outlined color="primary" class="me-4" @click="currentStep++">
                Skip
              </v-btn>
              <v-btn :disabled="!selectedGroupId" color="primary" @click="currentStep++" :loading="$store.getters.isLoading">
                Next
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="profile" step="6">
            <h3>Summary</h3>
            <p>That's all we need to know. Press 'Complete' to save your settings and get started.</p>
            <div class="d-flex">
              <v-btn v-if="currentStep > 1" text @click="currentStep--">
                Back
              </v-btn>

              <v-spacer />

              <v-btn color="primary" @click="submit" :loading="$store.getters.isLoading">
                Complete
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import { DateTime } from 'luxon'
import profileService from "@/services/profileService";
import groupService from "@/services/groupService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: {name: 'profile'} },
        { text: 'Onboarding', disabled: true },
      ],
      devices: [
        { text: 'Unknown or other', value: 'unknown', support: 'READ_MORE', faq: 'https://corporatefitness.helpscoutdocs.com/category/15-device-integrations' },
        { text: 'Garmin', value: 'garmin', support: 'YES', faq: 'https://corporatefitness.helpscoutdocs.com/article/55-garmin' },
        { text: 'Apple Health', value: 'apple-health', support: 'YES', faq: 'https://corporatefitness.helpscoutdocs.com/article/56-apple-health-and-apple-watch' },
        { text: 'COROS', value: 'coros', support: 'YES', faq: null },
        { text: 'Polar', value: 'polar', support: 'YES', faq: 'https://corporatefitness.helpscoutdocs.com/article/48-polar' },
        { text: 'Suunto', value: 'coros', support: 'YES', faq: 'https://corporatefitness.helpscoutdocs.com/article/52-suunto' },
        { text: 'Google Fit', value: 'google-fit', support: 'INDIRECT', faq: 'https://corporatefitness.helpscoutdocs.com/article/58-google-health-connect' },
        { text: 'Google Health Connect', value: 'google-hc', support: 'YES', faq: 'https://corporatefitness.helpscoutdocs.com/article/58-google-health-connect' },
        { text: 'Adidas Running / Runtastic', value: 'runtastic', support: 'INDIRECT', faq: 'https://corporatefitness.helpscoutdocs.com/article/157-adidas-running-runtastic' },
        { text: 'Decathlon Coach', value: 'decathlon', support: 'INDIRECT', faq: 'https://corporatefitness.helpscoutdocs.com/article/159-decat-coach' },
        { text: 'Nike Run Club', value: 'nike-run-club', support: 'INDIRECT', faq: 'https://corporatefitness.helpscoutdocs.com/article/155-nike-run-club' },
        { text: 'Runkeeper', value: 'runkeeper', support: 'INDIRECT', faq: 'https://corporatefitness.helpscoutdocs.com/article/156-runkeeper' },
        { text: 'Withings', value: 'withings', support: 'INDIRECT', faq: 'https://corporatefitness.helpscoutdocs.com/article/158-withings' },
        { text: 'Social Ride', value: 'social-ride', support: 'NO', faq: 'https://corporatefitness.helpscoutdocs.com/category/15-device-integrations' },
        { text: 'Wahoo', value: 'wahoo', support: 'NO', faq: 'https://corporatefitness.helpscoutdocs.com/category/15-device-integrations' },
        { text: 'Rouvy', value: 'rouvy', support: 'NO', faq: 'https://corporatefitness.helpscoutdocs.com/category/15-device-integrations' },
        { text: 'Kinomap', value: 'kinomap', support: 'NO', faq: 'https://corporatefitness.helpscoutdocs.com/category/15-device-integrations' },
        { text: 'Huawei Health', value: 'fulgaz', support: 'NO', faq: 'https://corporatefitness.helpscoutdocs.com/category/15-device-integrations' },
        { text: 'Fulgaz', value: 'fulgaz', support: 'NO', faq: 'https://corporatefitness.helpscoutdocs.com/category/15-device-integrations' },
      ],
      selectedDeviceValue: null,
      nameRules: [
        v => !!v || "Please enter your name",
      ],
      emailRules: [
        v => !v ||  /.+@.+/.test(v) || "E-mail must be valid",
      ],
      showDobPicker: false,
      profile: null,
      dob: null,
      gender: '_',
      privateProfile: '_',
      unit: '_',
      showOk: false,
      currentStep: 1,
      selectedGroupId: null,
      groups: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {

    async submit() {
      let response = await profileService.keepProfile();
      if (response.data.status == 'OK') {
        this.showOk = true;
      }
      else {
        this.$helpers.toastResponse(this, response.data);
      }
    },

    async getProfile() {
      if (this.user) {
        var response = await profileService.get();
        this.profile = response.data;
        this.dob = this.profile.dob;
        this.gender = this.profile.gender || '_';

        if (this.profile.meta != null && this.profile.meta.some(x => x.key == 'wmm.migrated.on')) {
          if (this.$route.query.silent == 'true') {
            this.$router.push({ name: 'profile' });
            return;
          }
          //this.$router.push({ name: 'profileOnboardingCompleted', query: { appcmd: 'close' }});
          return;
        }


        this.groups = (await groupService.list('TENANT_TEAM', 250)).data.data;
        if (tenant.hasTenantTeam) {
          var groupsResponse = (await profileService.myGroups('TENANT_TEAM')).data;
          if (groupsResponse && groupsResponse.data && groupsResponse.data.length) {
            this.selectedGroupId = groupsResponse.data[0].id;
          }
        }
      }
    },
    
    handleDobChange(e) {
      //console.log('Date picker emit new dob', e, 'parsed', this.formattedDob);
      this.dob = e;
      this.showDobPicker = false;
    },

    notSet(value) {
      return value == null || value === '' || value == '_' || value === 0;
    },
    
    async submit() {
      console.log('this.profile.gender',this.profile.gender)

      if (this.selectedGroupId) {
        (await profileService.joinGroup(this.selectedGroupId)).data;
      }
      let response = await profileService.put({
        name: this.profile.name,
        email: this.profile.email,
        //nationality: this.profile.nationality,
        unit: this.notSet(this.unit) ? null : this.unit,
        gender: this.notSet(this.gender) ? null : this.gender,
        dob: this.notSet(this.dob) ? null : this.dob,
        private: this.notSet(this.privateProfile) ? null : this.privateProfile,
        wmm_migration: true,
      });
      this.profileSavedSnackbar = true;
      // reload
      console.log('this.profile save result',response.data)
      if (response.data != null) {
        this.$router.push({ name: 'profileOnboardingCompleted', query: { appcmd: 'close' }});
      }
    }
  },
  computed: {
    selectedDevice() {
      return this.selectedDeviceValue && this.devices.find(x => x.value == this.selectedDeviceValue);
    },
    formattedDob: {
      get() {
        console.log('/// dob ', this.dob);
        return !this.dob ? null : DateTime.fromISO(this.dob).toLocaleString(DateTime.DATE_SHORT);
      },
      set(value) {
        //this.profile.dob = value;
        //console.log('Date picker emit value', value, 'parsed to dob:', this.profile.dob);
      }
    },
    preferencesValid() {
      return !this.notSet(this.privateProfile) && !this.notSet(this.unit);
    },
    isValid() {
      return this.profile && this.profile.email;
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
.v-stepper--alt-labels .v-stepper__step  { flex-basis: inherit;}
.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step  {background: grey; }
</style>

